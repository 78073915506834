import React, { useEffect, useState } from "react";
import axios from "axios";
import Title from "../components/Title";
import moment from "moment";

interface Article {
  title: string;
  categories: string[];
  link: string;
  pubDate: string;
  guid: string;
  description: string;
}

const Articles = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  useEffect(() => {
    axios
      .get(
        "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@ertemishakk"
      )
      .then((data) => {
        const res: Article[] = data.data.items;
        setArticles(res.filter((item) => item.categories.length > 0));
      });
  }, []);

  const toText = (node: string) => {
    let tag = document.createElement("div");
    tag.innerHTML = node;
    node = tag.innerText;
    return node;
  };

  return (
    <div>
      <Title title="# Articles" />
      {articles &&
        articles.map((article) => (
          <div key={article.guid} className="my-4">
            <a
              href={article.link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecorationColor: "rgb(217, 49, 48)" }}
            >
              <h1 className="title">{article.title}</h1>
            </a>
            <h1 className="subtitle">
              {moment(article.pubDate).format("MM-YYYY")}
            </h1>
            <h1 className="desc">
              {toText(article.description.substring(0, 250))}..
            </h1>
          </div>
        ))}
    </div>
  );
};

export default Articles;
