import React from "react";

const SocialLinks = () => {
  const links = [
    {
      logo: "fa-solid fa-envelope",
      href: "mailto:ertemishakk@gmail.com",
      label: "ertemishakk@gmail.com",
    },
    {
      logo: "fa-brands fa-github",
      href: "https://github.com/ertemishakk",
      label: "Github",
    },
    {
      logo: "fa-brands fa-linkedin",
      href: "https://www.linkedin.com/in/ishak-ertem-054b5b139?originalSubdomain=au",
      label: "Linkedin",
    },
  ];
  return (
    <div>
      {links.map((link) => (
        <p key={link.label}>
          <a
            href={link.href}
            style={{ color: "rgb(217,49,48)" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className={link.logo} style={{ marginRight: 10 }} />
            {link.label}
          </a>
        </p>
      ))}
    </div>
  );
};

export default SocialLinks;
