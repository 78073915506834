import React from "react";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import Title from "../components/Title";

const projects = [
  {
    title: "Remote Trails",
    link: "https://remotetrails.com.au",
    desc: "New face of Remote Trails, an Australian sustainable and ethical outdoor and lifestyle brand.",
  },
  {
    title: "Allsafe Precast",
    link: "https://allsafeprecast.com.au/",
  },
  {
    title: "Allsafe Labour Hire",
    link: "https://allsafelabour.com.au/",
  },
  {
    title: "OJE Australia",
    link: "https://ojeaustralia.com.au",
    desc: " Developed using MERN stack, ojeaustralia.com.au provides the most user friendly experience and feautres product reviews, advanced filtering, product search and much more.",
  },
];

const modules = [
  {
    title: "Tiktok-Pixel",
    link: "https://www.npmjs.com/package/tiktok-pixel",
    desc: "React Wrapepr for TikTok pixel for analytics reporting",
  },
  {
    title: "React Formik Stepper",
    link: "https://www.npmjs.com/package/react-formik-stepper",
    desc: "A flexible multistep wizard built for React and Formik",
  },
];

const Projects = () => {
  const dispatch = useAppDispatch();
  return (
    <div>
      <Title title="# Npm modules" />
      <div>
        {modules.map((module) => (
          <div key={module.title} className="my-4">
            <a
              href={module.link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecorationColor: "rgb(217, 49, 48)" }}
            >
              <h1 className="title">{module.title}</h1>
            </a>
            <h1 className="desc">{module.desc}</h1>
          </div>
        ))}
      </div>

      <div style={{ marginTop: 40 }}>
        <Title title="# Projects" />
        {projects.map((project) => (
          <div key={project.title} className="my-4">
            <a
              href={project.link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecorationColor: "rgb(217, 49, 48)" }}
            >
              <h1 className="title">{project.title}</h1>
            </a>
            <h1 className="desc">{project.desc}</h1>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
