import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Wrapper from "./components/Wrapper";
import { screens } from "./utils/screens";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {screens.map((screen) => (
            <Route
              key={screen.path}
              path={screen.path}
              element={
                <Wrapper>
                  <screen.component />
                </Wrapper>
              }
            />
          ))}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
