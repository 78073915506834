import React from "react";

const Title = ({ title }: { title: string }) => {
  return (
    <div>
      <p style={{ color: "rgb(230, 225,220", fontSize: 14, fontWeight: "600" }}>
        {title}
      </p>
    </div>
  );
};

export default Title;
