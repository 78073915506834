import React from "react";
import Title from "../components/Title";
import { Row, Col } from "reactstrap";

const experiences = [
  {
    title: "Software Developer",
    time: "Jan 2021 - Current",
    company: "Redcat Cloud Solutions",
    description: "Developing web, mobile and kiosk applications",
  },
  {title:'Full stack developer',
  time:"April 2019 - Jan 2021",
  company: "Frozberries Vic",
  description:"Expanding online existence"
},
  {
    title: "Freelancer",
    time: "Since 2018",
    description: "Full stack ecommerce sites - written on MERN Stack",
  },
];

const skills = [
  "React",
  "React Native",
  "Typescript",
  "NodeJS",
  "MongoDB",
  "Bootstrap",
  "Material UI",
];

const learning = ["Python", "Swift", "GraphQL", "Cypress", "Unit testing"];

const Experience = () => {
  return (
    <div>
      <Title title="# Job experience" />
      {experiences.map((experience) => (
        <div className="my-4" key={experience.title}>
          <h5 className="experience-title">{experience.title}</h5>
          <h5 className="experience-time">{experience.time}</h5>
          {experience.company && (
            <h5 className="experience-company">{experience.company}</h5>
          )}

          <h5 className="experience-desc">{experience.description}</h5>
        </div>
      ))}

      <Row style={{ marginTop: 50 }}>
        <Col>
          <div>
            <Title title="# Skills" />
            {skills.map((skill, index) => (
              <h5 className="skill-desc my-1" key={index}>
                <span className="skill-star">* </span>
                {skill}
              </h5>
            ))}
          </div>
        </Col>

        <Col>
          <div>
            <Title title="# Current interests" />
            {learning.map((skill, index) => (
              <h5 className="skill-desc my-1" key={index}>
                <span className="skill-star">* </span>
                {skill}
              </h5>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Experience;
