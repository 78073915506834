import React from "react";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { Drawer as NavDrawer } from "@mui/material";
import NavLinks from "./NavLinks";
import { setDrawer } from "../features/drawer";

const Drawer = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.drawer);

  return (
    <NavDrawer
      anchor="left"
      open={isOpen}
      onClose={() => dispatch(setDrawer(false))}
    >
      <div
        className="p-3"
        style={{
          width: "350px",
          lineHeight: 1,
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          backgroundColor: "#1e1e1e",
        }}
        role="presentation"
      >
        <NavLinks />
      </div>
    </NavDrawer>
  );
};

export default Drawer;
