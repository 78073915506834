import React from "react";
import { Row, Col, Navbar, NavbarBrand } from "reactstrap";
import useWindowDimensions from "./useWindowDimensions";
import Drawer from "./Drawer";
import NavLinks from "./NavLinks";
import { setDrawer } from "../features/drawer";
import { useAppDispatch } from "../app/hooks";

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  const { width, height } = useWindowDimensions();
  const dispatch = useAppDispatch();

  return (
    <div
      style={{
        backgroundColor: "#1e1e1e",
        padding: width > 791 ? 50 : 30,
        minHeight: "100vh",
      }}
    >
      <Drawer />
      {width < 791 && (
        <Navbar className="my-2">
          <NavbarBrand href="/" className="me-auto" style={{ color: "white" }}>
            ISHAK ERTEM
          </NavbarBrand>
          <i
            onClick={() => dispatch(setDrawer(true))}
            className="fa-solid fa-bars "
            style={{ color: "white", fontSize: 24, cursor: "pointer" }}
          ></i>
        </Navbar>
      )}

      <Row>
        {width > 791 && (
          <Col xs="3" style={{ width: 250 }}>
            <NavLinks />
          </Col>
        )}

        <Col>
          <div
            className="pb-5"
            style={{
              minHeight: "90vh",
              backgroundColor: "#2b2b2b",
              paddingTop: 15,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            {children}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Wrapper;
