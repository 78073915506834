import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "drawer",
  initialState: false,
  reducers: {
    setDrawer: (state, action: PayloadAction<boolean>) => {
      return action.payload;
    },
  },
});

export const { setDrawer } = slice.actions;
export default slice.reducer;
