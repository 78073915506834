import React from "react";
import Title from "../components/Title";
import Span from "../components/Span";
import Paragraph from "../components/Paragraph";
import SocialLinks from "../components/SocialLinks";

const InitialScreen = () => {
  return (
    <div>
      <div>
        <Title title="# About me" />
        <Paragraph>Hi there!</Paragraph>
     

        <Paragraph>
        I am a dedicated developer with a proven history of delivering innovative solutions across various platforms, including web, kiosk, mobile, and desktop applications. My expertise spans a wide range of technologies, and I am proficient in using React, React Native, Electron, Node.js, TypeScript, MongoDB, MySQL, and CSS frameworks such as Material UI and Bootstrap. I hold a Bachelor’s degree in Computer Information Systems from CUNY Baruch College.
        </Paragraph>
        <Paragraph>
        I take pride in my contributions to projects that have garnered a substantial user base, particularly in the UK and Australia, serving millions of people. My passion lies in crafting comprehensive software solutions using React Native, and I am well-versed in leveraging mono repo structures to streamline development processes.
        </Paragraph>
        <Paragraph>
        One of my key strengths is my ability to quickly learn and adapt to new languages and skills. I am committed to pushing the boundaries of technology and contributing to transformative projects in the world of software. This drive for continuous learning ensures that I stay at the forefront of the ever-evolving landscape of technology.
        </Paragraph>
      </div>{" "}
      <div style={{ marginTop: 40 }}>
        <Title title="# Education" />
        <h1 className="desc">City University of New York - Baruch College</h1>
        <h1 className="subtitle">
          Computer Information Systems, Information Risk Management and Cyber
          Security
        </h1>
        <h1 className="subtitle">Dec 2017</h1>
      </div>
      <div style={{ marginTop: 40 }}>
        <Title title="# Contact" />
        <SocialLinks />
      </div>
    </div>
  );
};

export default InitialScreen;
