import { INITIAL_ROUTE, EXPERIENCE, ARTICLES, PROJECTS } from "./routes";
import InitialScreen from "../screens/InitialScreen";
import Experience from "../screens/Experience";
import Articles from "../screens/Articles";
import Projects from "../screens/Projects";

export const screens: Screens[] = [
  {
    path: INITIAL_ROUTE,
    component: InitialScreen,
  },
  {
    path: EXPERIENCE,
    component: Experience,
  },
  {
    path: ARTICLES,
    component: Articles,
  },
  {
    path: PROJECTS,
    component: Projects,
  },
];
