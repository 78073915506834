import React, { useState } from "react";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { INITIAL_ROUTE, EXPERIENCE, PROJECTS, ARTICLES } from "../utils/routes";
import { setDrawer } from "../features/drawer";
import useWindowDimensions from "./useWindowDimensions";

const links = [
  {
    route: INITIAL_ROUTE,
    label: "about_me",
  },
  {
    route: EXPERIENCE,
    label: "job_experience",
  },
  {
    route: PROJECTS,
    label: "projects",
  },
  {
    route: ARTICLES,
    label: "articles",
  },
];

const NavLinks = () => {
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(true);
  const location = useLocation();
  const { width, height } = useWindowDimensions();

  return (
    <div
      style={{
        backgroundColor: "#2b2b2b",
        paddingTop: 15,
        paddingLeft: 20,
        paddingRight: 20,
        height: "100%",
      }}
    >
      {width > 791 && (
        <h1 style={{ color: "white", fontSize: 24 }}>ISHAK ERTEM</h1>
      )}

      <div
        style={{ color: "rgb(230, 225,220", cursor: "pointer" }}
        onClick={() => setVisible(!visible)}
      >
        {visible ? (
          <i className="fas fa-folder-open"></i>
        ) : (
          <i className="fa-solid fa-folder" />
        )}

        {" /"}
      </div>
      {visible && (
        <div style={{ paddingLeft: 10 }}>
          {links.map((link) => (
            <p style={{ margin: 5 }} key={link.route}>
              <Link
                onClick={() => {
                  dispatch(setDrawer(false));
                }}
                to={link.route}
                className="drawer-link"
                style={{
                  color:
                    link.route === location.pathname
                      ? "red"
                      : "rgb(165, 194, 97)",
                  textDecoration: "none",
                }}
              >
                <i
                  className="fas fa-code-branch"
                  style={{ marginRight: 5, color: "rgb(217,49,48)" }}
                ></i>
                {link.label}
              </Link>
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default NavLinks;
